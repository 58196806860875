"use client";

import React from "react";
import ThemeProvider from "./ThemeToggle/theme-provider";
import {
  AbstractIntlMessages,
  IntlError,
  IntlErrorCode,
  NextIntlClientProvider,
} from "next-intl";
import ConfigureAmplifyClientSide from "@/components/layout/configure-amplify";
import ReactQueryProvider from "@/app/utils/providers/ReactQueryProvider";
import { Toaster } from "sonner";
import { TooltipProvider } from "@evenli-platform/ui/components/tooltip";

function onError() {}

function getMessageFallback({
  namespace,
  key,
  error,
}: {
  error: IntlError;
  key: string;
  namespace?: string | undefined;
}) {
  const path = [namespace, key].filter((part) => part != null).join(".");

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return path + " is not yet translated";
  } else {
    return "Dear developer, please fix this message: " + path;
  }
}

export default function Providers({
  messages,
  locale,
  children,
}: {
  messages: AbstractIntlMessages;
  locale: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        <NextIntlClientProvider
          locale={locale}
          messages={messages}
          onError={onError}
          getMessageFallback={getMessageFallback}
        >
          <Toaster richColors position="bottom-center" />
          <ConfigureAmplifyClientSide />
          <ReactQueryProvider>
            <TooltipProvider>{children}</TooltipProvider>
          </ReactQueryProvider>
        </NextIntlClientProvider>
      </ThemeProvider>
    </>
  );
}
